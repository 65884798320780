/*
 * @Author: lusj lusj9568@163.com
 * @Date: 2023-06-12 17:19:23
 * @LastEditors: lusj lusj9568@163.com
 * @LastEditTime: 2025-02-21 16:44:56
 * @FilePath: \line_web\src\pages\share\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import './index.scss'
const DownloadUrl = () => {
  const ua = navigator.userAgent.toLowerCase()
  const model = ['vivo', 'oppo', 'xiaomi', 'hmscore']
  if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
    return 'https://apps.apple.com/cn/app/%E8%BF%88%E5%8A%BF%E7%BB%AE-%E8%BF%9E%E6%8E%A5%E6%97%A0%E7%95%8C/id6738117157'
  } else if (ua.indexOf('android') > -1) {
    if (model.some((item) => ua.indexOf(item) > -1)) {
      return 'market://details?id=maishiqi.app'
    } else if (ua.indexOf('micromessenger') > -1) {
      return 'notDownload'
    } else {
      return 'https://msg.messagesit.com/msqApp/%E8%BF%88%E5%8A%BF%E7%BB%AE.apk'
    }
  } else {
    return 'https://msg.messagesit.com/msqApp/%E8%BF%88%E5%8A%BF%E7%BB%AE.apk'
  }
}
const Download = () => {
  const [downloadUrl, setDownloadUrl] = useState('')
  const [tipsType, setTipsType] = useState(false)
  const tipsClassName = classNames('tips_box', {
    fadeIn: tipsType
  })
  const tipsTypeBtn = () => {
    setTipsType(true)
    setTimeout(() => { setTipsType(false) }, 3000)
  }
  useEffect(() => {
    setDownloadUrl(DownloadUrl())
  }, [])
  return (
    <div className="download_page">
      <div className="main_box">
        <div className="logo_box">
          <img className="img_box" alt="logo" src={require('@/assets/images/app_logo_512.png')} />
          <p className="text_box">社交拓圈新玩法</p>
        </div>
        {downloadUrl === 'notDownload' ? <div className="btn_box active not" onClick={tipsTypeBtn}>下载迈势绮 APP</div> :
          <a className="btn_box active" href={downloadUrl} target="_self">下载迈势绮 APP</a>}
        {downloadUrl !== 'notDownload' ? <a className="btn_box" href="maishiqi://pages/home">打开迈势绮 APP</a> : ''}
      </div>
      <div className="footer_box">
        <p className="text_box">开发者：上海迈势绮信息科技有限公司</p>
        <p className="text_box">运营者：上海迈势绮信息科技有限公司</p>
        <p className="text_box"><Link className="nav_box" to="/authority">应用权限</Link><Link className="nav_box" to="/agreement">隐私政策</Link><Link className="nav_box" to="/feature">功能介绍</Link></p>
        <div className="text_box"><p style={{ marginRight: '20px' }}>应用版本：v1.2.1</p><p>更新时间：2025-02-21</p></div>
      </div>
      <div className={tipsClassName}>点击右上角在浏览器中打开</div>
    </div>
  )
}
export default Download